import ThemisIssueFields from "@/components/issue-fields"
import ThemisIssueFormTemplates from "@/components/issue-form-templates"
import { mapGetters, mapActions } from "vuex"
import { TABS, ROUTE_NAME } from "@/constants"
export default {
  name      : "IssueFieldsAndForms",
  components: {
    ThemisIssueFields,
    ThemisIssueFormTemplates
  },
  data() {
    return {
      selectedTab    : null,
      fieldSystemName: null
    }
  },
  methods: {
    ...mapActions({
      addIssueFields         : "issueFields/addIssueFields",
      notify                 : "shared/notify",
      updateIssueField       : "issueFields/updateIssueField",
      addIssueFormTemplates  : "issueFormTemplates/addIssueFormTemplates",
      updateIssueFormTemplate: "issueFormTemplates/updateIssueFormTemplate"
    }),
    handleTabClickEvent(tabName) {
      this.selectedTab = this.tabs[tabName]

      const tabRouteMapping = {
        [TABS.ISSUE_FORMS] : ROUTE_NAME.ISSUE_FORM_TEMPLATES,
        [TABS.ISSUE_FIELDS]: ROUTE_NAME.ISSUE_FIELDS
      }

      const targetRouteName = tabRouteMapping[tabName]

      if (targetRouteName && this.$route.name !== targetRouteName) {
        this.$router.push({ name: targetRouteName })
      }
    },
    handleUpdateIssueField(value) {
      this.fieldSystemName = value.fieldSystemName
      this.updateIssueField({
        id          : value.id,
        sortingOrder: value.sortingOrder
      })
    }
  },
  computed: {
    ...mapGetters({
      fieldsV2                                 : "fields/fieldsV2",
      optionLists                              : "optionLists/optionLists",
      issueFields                              : "issueFields/issueFields",
      isAddingIssueFields                      : "issueFields/isAddingIssueFields",
      isIssueFieldsAdded                       : "issueFields/isIssueFieldsAdded",
      isSortingOrderUpdated                    : "issueFields/isSortingOrderUpdated",
      isIssueFormsEnabled                      : "configurations/isIssueFormsEnabled",
      isIssueFieldsEnabled                     : "configurations/isIssueFieldsEnabled",
      issueFormTemplates                       : "issueFormTemplates/issueFormTemplates",
      formTemplates                            : "formTemplates/formTemplates",
      formTemplateConfigurations               : "formTemplateConfigurations/formTemplateConfigurations",
      isAddingIssueFormTemplates               : "issueFormTemplates/isAddingIssueFormTemplates",
      isIssueFormTemplatesAdded                : "issueFormTemplates/isIssueFormTemplatesAdded",
      isSortingOrderUpdatedForIssueFormTemplate: "issueFormTemplates/isSortingOrderUpdated"
    }),
    tabs() {
      return {
        [TABS.ISSUE_FIELDS]: 0,
        [TABS.ISSUE_FORMS] : 1
      }
    },
    fieldsMap() {
      const fieldsMap = new Object()
      for (const field of this.fieldsV2) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    }
  },
  watch: {
    "$route.name": {
      immediate: true,
      handler  : function(name) {
        if(name === ROUTE_NAME.ISSUE_FIELDS || name === ROUTE_NAME.ISSUE_FIELD){
          this.selectedTab = this.tabs[TABS.ISSUE_FIELDS]
        } else if (name === ROUTE_NAME.ISSUE_FORM_TEMPLATES || name === ROUTE_NAME.ISSUE_FORM_TEMPLATE) {
          this.selectedTab = this.tabs[TABS.ISSUE_FORMS]
        }
      }
    },
    isIssueFieldsAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "997"
          })
        }
      }
    },
    isSortingOrderUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "315",
            parameters: {
              fieldSystemName: this.fieldSystemName
            }
          })
        }
      }
    },
    isIssueFormTemplatesAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "2120"
          })
        }
      }
    }
  }
}