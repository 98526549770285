import { getHeadersForTable } from "@/utils/table"
import {
  TABLE_NAMES,
  ISSUE_TYPES
} from "@/constants"
export default {
  name: "IssueFormTemplates",
  data: () => ({
    issueFormTemplates            : [],
    issueFormTemplatesToBeAdded   : [],
    showAddIssueFormTemplateDialog: false,
    issueFormTemplatesAreDraggable: false,
    draggingIssueFormTemplate     : undefined,
    dragEnterIssueFormTemplate    : undefined
  }),
  emits: ["addIssueFormTemplates"],
  props: {
    pFieldsMap                 : Object,
    pFormTemplates             : Array,
    pIssueFormTemplates        : Array,
    pFormTemplateConfigurations: Array,
    pIsAddingIssueFormTemplates: Boolean,
    pIsIssueFormTemplatesAdded : Boolean,
    pIsSortingOrderUpdated     : Boolean
  },
  computed: {
    headersForIssueFormTemplatesTable() {
      return getHeadersForTable(TABLE_NAMES.ISSUE_FORM_TEMPLATE_FOR_ISSUE_TYPE, this.$t.bind(this))
    },
    headersForAddIssueFormTemplatesTable() {
      return getHeadersForTable(TABLE_NAMES.ISSUE_FORM_TEMPLATE_ADD_FOR_ISSUE_TYPE, this.$t.bind(this))
    },
    sortedIssueFormTemplates() {
      let sortedIssueFormTemplates = []
      if (this.pIssueFormTemplates.length) {
        sortedIssueFormTemplates = [...this.pIssueFormTemplates]
        sortedIssueFormTemplates.sort((issueFormTemplate1, issueFormTemplate2) =>
          issueFormTemplate1.sortingOrder - issueFormTemplate2.sortingOrder)
      }
      return sortedIssueFormTemplates
    },
    itemsForIssueFormTemplatesTable() {
      let itemsForIssueFormTemplatesTable = []
      if (this.sortedIssueFormTemplates.length) {
        itemsForIssueFormTemplatesTable = this.sortedIssueFormTemplates.map(issueFormTemplate => {
          const fieldNames = this.getIssueFormTemplateFieldNames(issueFormTemplate.formTemplateId)
          return {
            id  : issueFormTemplate.id,
            name: this.pFormTemplates.find(
              formTemplate => formTemplate.id === issueFormTemplate.formTemplateId)?.name,
            fields      : fieldNames?.length ? fieldNames.join(", ") : this.$t("741"),
            sortingOrder: issueFormTemplate.sortingOrder
          }
        })
      }
      return itemsForIssueFormTemplatesTable
    },
    issueFormTemplateIds() {
      return this.pIssueFormTemplates.map(issueField => issueField.formTemplateId)
    },
    itemsForAddIssueFormTemplatesTable() {
      return this.pFormTemplates.filter(formTemplate =>
        !this.issueFormTemplateIds.includes(formTemplate.id) &&
        !formTemplate.reportForm
      ).map(formTemplate => {
        return {
          id    : formTemplate.id,
          name  : formTemplate.name,
          fields: this.getIssueFormTemplateFieldNames(formTemplate.id)?.length ?
            this.getIssueFormTemplateFieldNames(formTemplate.id).join(", ") :
            this.$t("741")
        }
      })
    },
    issueFormTemplateFieldsMap() {
      const issueFormTemplateFieldsMap = new Map()
      for (const configuration of this.pFormTemplateConfigurations) {
        let fields = [this.pFieldsMap[configuration.fieldId]]
        if (issueFormTemplateFieldsMap.has(configuration.formTemplateId)) {
          fields = [...fields, ...issueFormTemplateFieldsMap.get(configuration.formTemplateId)]
        }
        issueFormTemplateFieldsMap.set(configuration.formTemplateId, fields)
      }
      return issueFormTemplateFieldsMap
    },
    selectedIssueFormTemplate() {
      if (this.$route.params.id) {
        return this.pIssueFormTemplates.find(
          issueFormTemplate => issueFormTemplate.id === +this.$route.params.id
        )
      }
    }
  },
  methods: {
    handleClickOnRow(item) {
      const isCurrentRouteIssueFieldsAndForms   = this.$route.name === "issue-form-templates"
      const isDifferentIssueFormTemplateClicked = this.$route.name === "issue-form-template" && this.selectedIssueFormTemplate.id !== item.id
      if (isCurrentRouteIssueFieldsAndForms || isDifferentIssueFormTemplateClicked) {
        this.$router.push({
          name  : "issue-form-template",
          params: {
            id: item.id
          }
        })
      }
    },
    getIssueFormTemplateFieldNames(formTemplateId) {
      return this.issueFormTemplateFieldsMap
        ?.get(formTemplateId)
        ?.map(field => field?.systemName)
    },
    cancelAddIssueFormTemplates() {
      this.showAddIssueFormTemplateDialog = false
    },
    addFormTemplatesToIssue() {
      this.$emit("addIssueFormTemplates", this.issueFormTemplatesToBeAdded.map(formTemplate => ({
        formTemplateId: formTemplate.id,
        typeId        : ISSUE_TYPES[0].id
      })))
    },
    getClassForIssueFormTemplateRow(issueFormTemplate) {
      let classForIssueFormTemplateRow = ""
      if (issueFormTemplate.id === this.selectedIssueFormTemplate?.id) {
        classForIssueFormTemplateRow = "blue lighten-5"
      } else if (issueFormTemplate.id === this.dragEnterIssueFormTemplate?.id &&
        issueFormTemplate.id !== this.draggingIssueFormTemplate?.id) {
        if (this.dragEnterIssueFormTemplate?.sortingOrder < this.draggingIssueFormTemplate?.sortingOrder) {
          classForIssueFormTemplateRow += "drop-row-down"
        } else {
          classForIssueFormTemplateRow += "drop-row-up"
        }
      }
      return classForIssueFormTemplateRow
    },
    issueFormTemplateDragStart(issueFormTemplate) {
      this.draggingIssueFormTemplate  = issueFormTemplate
      this.dragEnterIssueFormTemplate = this.draggingIssueFormTemplate
    },
    issueFormTemplateDragEnter(issueFormTemplate) {
      this.dragEnterIssueFormTemplate = issueFormTemplate
    },
    onIssueFormTemplateDragOver(event) {
      event.preventDefault()
    },
    onIssueFormTemplateDragEnd() {
      if(this.draggingIssueFormTemplate.id !== this.dragEnterIssueFormTemplate.id) {
        const isIssueFormTemplateDraggedUp =
          !!(this.dragEnterIssueFormTemplate.sortingOrder - this.draggingIssueFormTemplate.sortingOrder)
        const sortingOrder                 = isIssueFormTemplateDraggedUp ?
          this.dragEnterIssueFormTemplate.sortingOrder : this.dragEnterIssueFormTemplate.sortingOrder - 1

        this.$emit("updateIssueFormTemplate", {
          id: this.draggingIssueFormTemplate.id,
          sortingOrder
        })
      }
    }
  },
  watch: {
    pIsIssueFormTemplatesAdded: {
      handler: function(value) {
        if (value) {
          this.showAddIssueFormTemplateDialog = false
        }
      }
    },
    showAddIssueFormTemplateDialog: {
      handler: function(value) {
        if (!value) {
          this.issueFormTemplatesToBeAdded = []
        }
      }
    },
    pIsSortingOrderUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.draggingIssueFormTemplate  = undefined
          this.dragEnterIssueFormTemplate = undefined
        }
      }
    }
  }
}